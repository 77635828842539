<template>
  <router-link
    :to="{
      name: 'LiffOrderListShow',
      params: { id: order.id },
      query: { source: $route.query.source },
    }"
    v-slot="{ navigate }"
    custom
  >
    <div class="s-order-item" @click="navigate">
      <div class="s-order-item-image" v-if="showImage && image">
        <img :src="image" />
      </div>
      <template v-if="arrangedFieldsForGrid.length > 0">
        <div class="s-order-item-info">
          <div class="s-order-item-field" v-for="(field, j) in arrangedFieldsForGrid" :key="j">
            <template v-if="field.type === 'ntd'">
              <p class="s-text-primary">
                <span class="s-text-sm">NT$ </span>
                <span class="s-font-bold">{{ $currency(getOrderData(field.key), '') }}</span>
              </p>
            </template>
            <span :class="getFieldClass(field)" v-else>
              {{ field.value }}{{ getOrderData(field.key) }}
            </span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="s-order-item-info">
          <div class="s-space-y-2 s-mr-auto">
            <p class="s-text-gray-darker s-font-semibold">
              訂單編號：{{ order.order_number }}
            </p>
              <p class="s-text-gray s-text-xs s-whitespace-nowrap">
                {{ datetime }}
              </p>
          </div>
          <div class="s-flex s-flex-col s-items-end">
            <p>
              <span class="s-tag">{{ order.order_status }}</span>
            </p>
            <p class="s-text-primary">
              <span class="s-text-sm">NT$ </span>
              <span class="s-font-bold">{{ $currency(order.amount, '') }}</span>
            </p>
          </div>
        </div>
      </template>
    </div>
  </router-link>
</template>

<script>
import _ from 'lodash'
import moment from "moment";

export default {
  inject: ["$currency"],
  props: {
    order: {
      type: Object,
      required: true,
    },
    fieldSetting: {
      type: Object,
      required: false,
    },
    showImage: Boolean,
  },
  computed: {
    image() {
      return null;
    },
    datetime() {
      const shouldParse = _.get(this.order, 'should_parse_order_at', true)

      if (false === shouldParse) {
        return this.order.order_at
      }

      return moment(this.order.order_at).format("YYYY-MM-DD HH:mm");
    },
    arrangedFieldsForGrid() {
      const fields = _.get(this.fieldSetting, 'list', [])
      return _.sortBy(fields, ['order', 'position'])
    },
  },
  methods: {
    getOrderData(key) {
      if (key === 'order_at') return this.datetime
      return this.order[key] ?? "";
    },
    getFieldClass(field) {
      if (field.type === 'bold') return "s-text-gray-darker s-font-semibold";
      if (field.type === 'text') return "s-text-gray s-text-xs";
      if (field.type === 'tag') return "s-tag s-text-gray s-text-xs";
    },
  },
};
</script>

<style  scoped>

.s-order-item {

  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: var(--s-white);

  box-shadow: rgb(from var(--s-primary) r g b / .5)  0px 2px 8px 0px;
  border-radius: 5px;
}

.s-order-item-image {
  flex-shrink: 0;
  margin-right: 0.5rem;
  font-size: 0;
  width: 5rem;
  height: 5rem;
}

.s-order-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.s-order-item-info {
  padding: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px 16px;
}

.s-order-item-field:nth-child(even) {
  text-align: right;
}
</style>
